@font-face {
  font-family: hindVadodaraRegular;
  src: url("./fonts/Hind_Vadodara/HindVadodara-Regular.ttf");
}

@font-face {
  font-family: hindVadodaraLight;
  src: url("./fonts/Hind_Vadodara/HindVadodara-Light.ttf");
}

.table {
  --bs-table-bg: none;
}
