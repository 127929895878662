/* Apply these styles to make the alert box and button smaller */
.smallAlert {
    width: 26%;
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Adjust z-index to make sure it appears on top */
  }
  
  
  
  .smallButton {
    padding: 5px 10px; /* Adjust the padding as needed */
  }
  