/* Style for captcha container */
.captcha-container {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 10px;
}
.captcha-text {
  display: block;
  font-size: 15px;
  text-align: center;
  color: rgb(33, 67, 130);
  font-weight: bold;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 3px;
  margin-bottom: 1px;
  width: 19%;
  margin-left: 35%;
  text-decoration: line-through;
}

.refresh-icon {
  cursor: pointer;
  margin-left:60%;
  margin-top: -6%;
}
