.custom-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  color: #495057;
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.25rem 0.7rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 90%;
}

.err-msg {
  font-size: 12px;
  color: red;
}

.form-label {
  font-family: hindVadodaraRegular;
  font-size: 13px;
  color: #214382;
}

.section-heading {
  font-family: hindVadodaraLight;
}

/* Header Component Style */
.header-row-1 {
  background-color: #ddeaf6;
  height: 25px;
}
.header-row-2 {
  height: 25px;
}
.header-col {
  height: 25px;
}

/* for the list view variants */

.list-item-success,
.list-item-success:hover {
  background-color: #d4edda; /* Example color for success */
}

.list-item-danger,
.list-item-danger:hover {
  background-color: #f8d7da; /* Example color for danger */
}

.list-item-warning,
.list-item-warning:hover {
  background-color: #fff3cd; /* Example color for warning */
}

/* In your CSS file (e.g., App.css) */
.border-right {
  border-right: 2px solid #ccc; /* Vertical divider style */
}
