.table th, .table td {
    font-size: 14px;
    color: #214382;
  }
  
  .table th{
      color: #214382;
  }
  /* Adjust column widths for specific columns */
  .table th:nth-child(3), .table td:nth-child(3) {
    width: 100px; /* Adjust the width as needed */
  }