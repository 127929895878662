.mainHeading {
    color: #214382;
    text-align: center;
    margin-top: 4rem;
  }
  
.myrow {
        justify-content: center;
        margin-left: -80%;
        color: #214382;
        font-size: small;
  }

  .myCol {
    text-align: center;
    margin-top: 4rem; /* Adjust the margin top as needed */
    color: #214382;
  }

  /* TableStyles.css */

.table-container {
    padding: 10%;
  }
  
  .table-header th:nth-child(1) {
    width: 20%;
  }
  
  .table-header th:nth-child(4) {
    width: 10%;
  }
  
  .table-actions {
    color: #214382;
    width: 33px;
    height: 24px;
  }
  .custom-col {
    /* Add your custom styles here */
    /* For example: */
    font-family: Arial, sans-serif;
    font-size: 13px;
    color:#214382
    /* Add more styles as needed */
  }
  
  
  
  
  
  
